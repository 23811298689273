import { add } from 'date-fns';

import { useGetMembershipOfferings } from 'apps-common/hooks/useGetMembershipOfferings';

const TRIAL_LENGTH = 1;

interface PrepaidInfo {
  billingStartDate: Date;
  isPrepaid: boolean;
  prepaidMonths: number;
}

export const useGetPrepaidInfo = (): PrepaidInfo => {
  const { data: offerings } = useGetMembershipOfferings();
  const today = new Date();
  const prepaidMonths = offerings?.prepaidMonths ?? 0;

  const billingStartDate = add(today, { months: TRIAL_LENGTH + prepaidMonths });

  return {
    isPrepaid: prepaidMonths > 0,
    billingStartDate,
    prepaidMonths,
  };
};
