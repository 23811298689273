import { ReactNode, useEffect } from 'react';
import { FormattedDate, useIntl } from 'react-intl';

import { styled } from 'styled-components';

import { useGetAccount } from 'apps-common/hooks/useGetAccount';
import { useGetMembershipOfferings } from 'apps-common/hooks/useGetMembershipOfferings';
import { Subscription } from 'apps-common/types';
import { logger } from 'apps-common/utils/logger';
import { periodToProductBillingNameKey, periodToProductPlanKey } from 'apps-common/utils/productLocalization';
import { isMobileDevice } from 'apps-common/utils/userAgent';
import { t } from 'translations';
import { Form, Header, Loader, SubmitButton } from 'ui';
import { MainContainer } from 'ui/styles/containers';
import { Background } from 'ui/styles/layout';
import { Text } from 'ui/styles/text';

import { useGetPrepaidInfo } from '../hooks/useGetPrepaidInfo';
import { useTrackFunnelCompleted } from '../hooks/useTrackFunnelCompleted';

const BackToAppButton = styled(SubmitButton)`
  min-width: 365px;
`;

const Container = styled.div(
  ({ theme }) => `
  padding: ${theme.margin}
`,
);

const Hr = styled.hr(
  ({ theme }) => `
  margin: ${theme.margin} 0;
  border: 1px solid ${theme.colors.grayMedium};
`,
);

const List = styled.ul(
  ({ theme }) => `
  padding: 0;
  margin: 0;

  li {
    margin: ${theme.margin} 0;
  }
`,
);

const Row = styled.li`
  display: flex;
  justify-content: space-between;
`;

interface SummaryRowProps {
  label: ReactNode;
  value: ReactNode;
  note?: ReactNode;
}

const SummaryRow = ({ label, value, note }: SummaryRowProps) => {
  return (
    <Row>
      <Text as="p">{label}</Text>
      <div>
        <Text as="p">{value}</Text>
        {note && (
          <Text as="p" $textAlign="right" $fontSize="xsmall" $color="grayLight">
            {note}
          </Text>
        )}
      </div>
    </Row>
  );
};

interface MembershipSummaryProps {
  recurringFee: NonNullable<Subscription['recurringFee']>;
}

const MembershipSummary = ({ recurringFee }: MembershipSummaryProps) => {
  const { billingStartDate, isPrepaid, prepaidMonths } = useGetPrepaidInfo();
  const intl = useIntl();

  const prepaidRow = isPrepaid ? (
    <SummaryRow
      key="prepaid"
      label={t('membership_hub_signup_X_month_prepaid_plan', { prepaidMonths })}
      value={t('membership_hub_signup_prepaid_applied')}
    />
  ) : null;

  return (
    <Background>
      <Container>
        <Text as="h2">{t('membership_hub_signup_membership_summary_title')}</Text>
        <Hr />
        <List>
          <SummaryRow
            key="trial"
            label={t('membership_hub_signup_trial_month')}
            value={t('membership_hub_signup_free_price')}
          />
          {prepaidRow}
          <SummaryRow
            key="rate-plan"
            label={t(periodToProductBillingNameKey(recurringFee.billingPeriod))}
            value={t(periodToProductPlanKey(recurringFee.billingPeriod), {
              price: intl.formatNumber(recurringFee.amount, {
                style: 'currency',
                currency: recurringFee.currency,
                currencyDisplay: 'narrowSymbol',
              }),
            })}
            note={t('membership_hub_signup_membership_summary_billing_start_date', {
              date: <FormattedDate value={billingStartDate} format="default" />,
            })}
          />
        </List>
      </Container>
    </Background>
  );
};

export const ConfirmationPage = () => {
  useTrackFunnelCompleted();
  // TODO: Check if we can manage with single API call somehow
  const { data, error: errorAccount, isFetching: isFetchingAccount } = useGetAccount();
  const { error: errorOfferings, isFetching: isFetchingOfferings } = useGetMembershipOfferings();

  const recurringFee = data?.account.currentSubscription?.recurringFee;

  // This page is rendered for users with a successful CreateSubscription mutation
  // Do not render error page
  const showSummary = !errorAccount && !errorOfferings && recurringFee;

  // Just log an error
  useEffect(() => {
    if (errorAccount) {
      logger.warn(`Error in fetching account details`, { error: errorAccount });
    }
  }, [errorAccount]);

  useEffect(() => {
    if (errorOfferings) {
      logger.warn(`Error in fetching account offerings`, { error: errorOfferings });
    }
  }, [errorOfferings]);

  const isFetching = isFetchingAccount || isFetchingOfferings;
  if (isFetching) {
    return <Loader />;
  }

  const title = t('membership_hub_signup_confirmation_title');
  const isMobile = isMobileDevice();
  const subtitle = isMobile
    ? t('membership_hub_signup_confirmation_mobile_subtitle')
    : t('membership_hub_signup_confirmation_desktop_subtitle');

  // TODO: Rewrite Header component to support children
  return (
    <>
      <Header appType="signup" pageType="create" title={title} subTitle={subtitle} testId="signup-complete-header" />
      <MainContainer>
        <Form as="div">
          {showSummary && <MembershipSummary recurringFee={recurringFee} />}
          {isMobile && (
            <BackToAppButton onClick={() => window.open('oura://v1/route/home')} data-testid="back-to-app-button">
              {t('membership_signup_setup_complete_back_to_app_button')}
            </BackToAppButton>
          )}
        </Form>
      </MainContainer>
    </>
  );
};
