import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router';

import { useMutation, useMutationState } from '@tanstack/react-query';
import { passwordRegex } from 'common-utils';
import { SignupRequestBodyAcceptHealthDataProcessingEnum, SignupResponse } from 'oura-account-api-client';
import { styled } from 'styled-components';

import { useAuth } from 'apps-common/context/auth';
import { createUser, useGetSignup } from 'apps-common/hooks/AccountApi';
import { useCreateEntitlementSubscription } from 'apps-common/hooks/useCreateEntitlementSubscription';
import { useCreatePartnershipSubscription } from 'apps-common/hooks/useCreatePartnershipSubscription';
import { useGetMembershipOfferings } from 'apps-common/hooks/useGetMembershipOfferings';
import { resetAllSlices, SignupInfo } from 'apps-common/store';
import { SignupMembershipType } from 'apps-common/types';
import { AccountApiError } from 'apps-common/utils/AccountApiError';
import { identify, setUserId, track } from 'apps-common/utils/analytics';
import { throwError } from 'apps-common/utils/errorHandler';
import { Flags, useFlag } from 'apps-common/utils/featureFlags';
import { isEntitlementUser, isGen2User, isPayingUser } from 'apps-common/utils/helpers';
import { logger } from 'apps-common/utils/logger';
import { t } from 'translations';
import { Button, Checkbox, CTALoader, EmailField, Form, Header, Loader, PasswordField } from 'ui';
import { MainContainer } from 'ui/styles/containers';
import { Link, SmallText } from 'ui/styles/text';
import { media } from 'ui/themes/signup';

import { routes } from '../routes';
import { useStore } from '../store';

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
  padding: 0 10px;
`;

const LoginForm = styled(Form)`
  & > button:last-child {
    align-self: center;
  }

  ${media.xlarge`
      & > button:last-child {
        align-self: flex-end;
      }
    `}
`;

const StartButton = styled(Button)`
  min-width: 365px;
`;

export interface FormData {
  email: string;
  password: string;
  userAgreement: SignupRequestBodyAcceptHealthDataProcessingEnum;
  marketingAgreement: boolean;
}

const useCreateUser = () => {
  const { login } = useAuth();
  const setAccountId = useStore((state) => state.setAccountId);
  const setEmail = useStore((state) => state.setEmail);

  return useMutation<SignupResponse, AccountApiError, FormData & { signupInfo: SignupInfo }>({
    mutationFn: createUser,
    onSuccess(res: SignupResponse, vars) {
      setAccountId(res.accountId);
      setEmail(vars.email);
      setUserId(res.analyticsId);
      identify({ traits: { isHipaa: res.isHipaa } });
      logger.info('New account created');
      track({ event: 'Account Created' });
      login();
    },
  });
};

const useFetchOfferingData = (userCreated: boolean) => {
  const { loggedIn } = useAuth();
  const { error: errorOnOfferrings, refetch: refetchMembershipOfferings } = useGetMembershipOfferings(false);

  useEffect(() => {
    if (loggedIn && userCreated) void refetchMembershipOfferings();
  }, [userCreated, loggedIn, refetchMembershipOfferings]);

  useEffect(() => {
    if (errorOnOfferrings && userCreated) {
      throwError('errorOnGettingMembershipOfferings', errorOnOfferrings);
    }
  }, [errorOnOfferrings, userCreated]);
};

const useTrackCustomerType = (userCreated: boolean) => {
  const setUserType = useStore((state) => state.setUserType);
  const { data: membershipOfferings } = useGetMembershipOfferings(false);

  useEffect(() => {
    if (membershipOfferings && userCreated) {
      const {
        membershipStatus: { type: userType, subscriptionFound },
        prepaidMonths,
        eligibleForPartnership,
      } = membershipOfferings;

      setUserType(userType);

      if (eligibleForPartnership) {
        track({
          event: 'Partnership Customer Found',
          payload: {
            existingSubscription: subscriptionFound,
            prepaidMonths,
          },
        });
        return;
      }

      switch (userType) {
        case SignupMembershipType.PAYING: {
          track({
            event: 'Paying Customer Found',
            payload: {
              existingSubscription: subscriptionFound,
              prepaidMonths,
            },
          });
          break;
        }

        case SignupMembershipType.ENTITLEMENT: {
          track({
            event: 'Entitlement Customer Found',
            payload: {
              existingSubscription: subscriptionFound,
              prepaidMonths,
            },
          });
          break;
        }

        case SignupMembershipType.LEGACY: {
          track({
            event: 'Legacy Customer Found',
            payload: {
              existingSubscription: subscriptionFound,
              prepaidMonths,
            },
          });
          break;
        }
      }
    }
  }, [membershipOfferings, setUserType, userCreated]);
};

const useCreateSubscription = (userCreated: boolean) => {
  const { data: membershipOfferings } = useGetMembershipOfferings(false);
  const membershipType = membershipOfferings?.membershipStatus.type;
  const subscriptionFound = membershipOfferings?.membershipStatus.subscriptionFound;
  const eligibleForPartnership = membershipOfferings?.eligibleForPartnership;

  const {
    mutate: createEntitlementSubscription,
    isSuccess: entitlementSubscriptionIsSuccess,
    error: entitlementSubscriptionError,
  } = useCreateEntitlementSubscription();

  const {
    mutate: createPartnershipSubscription,
    isSuccess: partnershipSubscriptionIsSuccess,
    error: partnershipSubscriptionError,
  } = useCreatePartnershipSubscription();

  // Create subscription for entitlement users
  useEffect(() => {
    if (userCreated && membershipType && isEntitlementUser(membershipType) && !subscriptionFound) {
      createEntitlementSubscription();
    }
  }, [createEntitlementSubscription, membershipType, subscriptionFound, userCreated]);

  // Create subscription for partnership users
  useEffect(() => {
    if (userCreated && eligibleForPartnership && !subscriptionFound && membershipType && !isGen2User(membershipType)) {
      createPartnershipSubscription();
    }
  }, [createPartnershipSubscription, eligibleForPartnership, membershipType, subscriptionFound, userCreated]);

  // Track subscription creation
  useEffect(() => {
    if (entitlementSubscriptionIsSuccess) {
      track({ event: 'Entitlement Subscription Created' });
    }
    if (partnershipSubscriptionIsSuccess) {
      track({ event: 'Partnership Subscription Created' });
    }
  }, [entitlementSubscriptionIsSuccess, partnershipSubscriptionIsSuccess]);

  if (entitlementSubscriptionError) {
    throw entitlementSubscriptionError;
  }
  if (partnershipSubscriptionError) {
    throw partnershipSubscriptionError;
  }
};

const useHandleNextPage = (ready: boolean) => {
  const navigate = useNavigate();
  const { data: offeringData } = useGetMembershipOfferings(false);
  const prepaidPlusFlag = useFlag(Flags.PREPAID_PLUS);

  const createPartnershipSubscriptionMutationState = useMutationState({
    filters: { mutationKey: ['createPartnershipSubscription'] },
  });
  const createEntitlementSubscriptionMutationState = useMutationState({
    filters: { mutationKey: ['createEntitlementSubscription'] },
  });

  useEffect(() => {
    if (!ready || !offeringData) return;

    const {
      membershipStatus: { type, subscriptionFound },
      eligibleForPartnership,
      prepaidMonths,
    } = offeringData;

    if (eligibleForPartnership) {
      if (
        createPartnershipSubscriptionMutationState.length > 0 &&
        createPartnershipSubscriptionMutationState[0].status === 'success'
      ) {
        navigate(routes.complete);
      }
      return; // isPayingUser() will return true for partnership users
    }

    if (isEntitlementUser(type)) {
      if (
        createEntitlementSubscriptionMutationState.length > 0 &&
        createEntitlementSubscriptionMutationState[0].status === 'success'
      ) {
        navigate(routes.complete);
      }
      return;
    }

    if (isPayingUser(type)) {
      if (prepaidMonths > 0 && prepaidPlusFlag) {
        navigate(routes.prepaidMoment);
      } else {
        navigate(subscriptionFound ? routes.complete : routes.address);
      }
    }

    if (isGen2User(type)) {
      // Gen2 users don't have subscriptions. Always to complete page after creating an account
      navigate(routes.complete);
    }
  }, [navigate, offeringData, ready]);
};

const useGetOnetimeToken = () => {
  const [searchParams] = useSearchParams();
  const oneTimeSignupToken = searchParams.get('token') ?? '';

  useEffect(() => {
    if (!oneTimeSignupToken) {
      throwError('missingSignupToken');
    }
  }, [oneTimeSignupToken]);

  return oneTimeSignupToken;
};

const useGetAccountData = () => {
  const oneTimeSignupToken = useGetOnetimeToken();
  const signup = useGetSignup(oneTimeSignupToken);
  const { error: errorOnAccountStatus } = signup;

  if (errorOnAccountStatus) {
    if (errorOnAccountStatus instanceof AccountApiError && errorOnAccountStatus.code === 'OneTimeTokenUsed') {
      throwError('oneTimeTokenAlreadyUsed', errorOnAccountStatus);
    }
    throw errorOnAccountStatus;
  }

  return signup;
};

const useTrackIdentity = () => {
  const { data: accountData } = useGetAccountData();
  const email = accountData?.email;

  useEffect(() => {
    if (email) {
      identify({ traits: { email } });
    }
  }, [email]);
};

const useTrackValidToken = () => {
  const { data: accountStatusData } = useGetAccountData();
  useEffect(() => {
    if (accountStatusData) {
      logger.info('Signup token validated');
      track({
        event: 'Signup Token Valid',
        payload: {
          accountStatus: accountStatusData.accountStatus,
          ringSerialStatus: accountStatusData.ringSerialStatus,
        },
      });
    }
  }, [accountStatusData]);
};

const readAndAgree = t('membership_signup_account_setup_readandagree', {
  TermsLink: (linkText) => (
    <Link href="https://ouraring.com/terms-and-conditions" target="_blank" $fontSize="12px" $decoration="underline">
      {linkText}
    </Link>
  ),
  WebsitePrivacyPolicyLink: (linkText) => (
    <Link href="https://ouraring.com/privacy-policy" target="_blank" $fontSize="12px" $decoration="underline">
      {linkText}
    </Link>
  ),
});

export const SignUpWelcome = () => {
  const [showPassword, setShowPassword] = useState(false);
  const intl = useIntl();
  const setSignupInfo = useStore((state) => state.setSignupInfo);

  const pendingMutations = useMutationState({
    filters: { status: 'pending' },
  });

  useEffect(() => {
    // reset zustand store on start page to avoid stale data
    resetAllSlices();
  }, []);

  useTrackIdentity();
  useTrackValidToken();
  const token = useGetOnetimeToken();
  const {
    data: accountStatusData,
    isFetching: isFetchingAccountStatus,
    isError: isErrorOnFecthingAccountStatus,
    error,
  } = useGetAccountData();

  const { error: errorOnCreateUser, isSuccess: createUserSucceded, mutate: createUserMutation } = useCreateUser();

  const { isFetching: isFetchingOffering } = useGetMembershipOfferings(false);

  const { register, handleSubmit, formState, setError, setValue } = useForm<FormData>({
    criteriaMode: 'all',
    mode: 'onTouched',
  });

  useEffect(() => {
    if (errorOnCreateUser) {
      const message =
        (errorOnCreateUser.detail as { errors?: { message: string }[] })?.errors?.[0]?.message ??
        errorOnCreateUser.message;
      setError('password', { type: 'validate', message });
    }
  }, [errorOnCreateUser, setError]);

  useFetchOfferingData(createUserSucceded);
  useTrackCustomerType(createUserSucceded);
  useCreateSubscription(createUserSucceded);
  useHandleNextPage(createUserSucceded && !pendingMutations.length);

  if (isFetchingAccountStatus) {
    return <Loader />;
  }

  if (isErrorOnFecthingAccountStatus || !accountStatusData) {
    throwError('signupUnknown', error);
  }

  setValue('email', accountStatusData.email);

  const signupInfo = { ...accountStatusData, token };
  setSignupInfo(signupInfo);

  const onSubmit = handleSubmit((data) => {
    createUserMutation({ ...data, signupInfo });
    track({
      event: 'CTA Clicked',
      payload: { cta: 'next', action: 'onboarding_next_step' },
    });
  });

  const passwordValidation = new RegExp(passwordRegex);
  const isButtonDisabled = isFetchingOffering || isFetchingAccountStatus || pendingMutations.length > 0;

  const title = t('membership_hub_membership_setup');

  return (
    <>
      <Header appType="signup" pageType="landing" title={title} testId="signup-welcome-header"></Header>
      <MainContainer>
        <LoginForm onSubmit={onSubmit}>
          <EmailField disabled id="email" {...register('email')} />
          <PasswordField
            id="password"
            showPassword={showPassword}
            onRevealIconClick={() => {
              setShowPassword(!showPassword);
            }}
            placeholder={intl.formatMessage({
              id: 'membership_signup_account_password_new_password',
            })}
            error={formState.errors.password?.message}
            {...register('password', {
              required: {
                value: true,
                message: 'Password required',
              },
              validate: (value) => passwordValidation.test(value) || 'Password does not meet requirements',
            })}
          />
          <SmallText $fontSize="xsmall" $margin="10px">
            {t('membership_hub_pw_creation_requirement_policy')}
          </SmallText>
          <CheckboxContainer>
            <Checkbox
              id="userAgreement"
              $fontSize="xsmall"
              htmlFor="userAgreement"
              // TODO: Add error label
              {...register('userAgreement', {
                required: {
                  value: true,
                  message: 'Agreement required',
                },
              })}
            >
              {readAndAgree}
            </Checkbox>
            <Checkbox $fontSize="xsmall" htmlFor="marketingAgreement" {...register('marketingAgreement')}>
              {t('membership_signup_account_setup_exclusivehealth')}
            </Checkbox>
          </CheckboxContainer>
          <SmallText $fontSize="xsmall" $margin="10px">
            {t('membership_hub_first_month_on_us')}
          </SmallText>
          <StartButton disabled={!formState.isValid || isButtonDisabled} data-testid="password-submit-button">
            {isButtonDisabled ? <CTALoader /> : t('membership_signup_welcome_page_button_getstarted')}
          </StartButton>
        </LoginForm>
      </MainContainer>
    </>
  );
};
